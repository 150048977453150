import React, {
  useMemo,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { debounce } from 'lodash-es'
import { colors, misc, mixins } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import { DoubleChevronRight } from '@dqp/common/components/icons'
import { useClickAway, useScrollbarWidth, useEvent } from 'react-use'
import SidebarLink, { SidebarLinkPropTypes } from './SidebarLink'
import settingsIcon from '#images/settingsIcon.svg'
import userIcon from '#images/userIcon.svg'
import helpIcon from '#images/helpIcon.svg'
import logoutIcon from '#images/logoutIcon.svg'
import routes from '#globals/routes'
import Logo from '#components/Logo'
import { useWelcomeTourModalContext } from '#context/WelcomeTourModalContext'
import { useLogout } from '#api/auth'

export const logoWidth = 70
const MAX_SIDEBAR_WIDTH = 320

const settingsLink = {
  to: routes.app.to,
  icon: settingsIcon,
  text: 'Manage Subjects',
  partiallyActive: false,
}

const userProfileLink = {
  to: routes.account.to,
  icon: userIcon,
  text: 'My Account',
  partiallyActive: false,
}

const helpLink = {
  icon: helpIcon,
  text: 'Help',
  partiallyActive: false,
}

const logoutLink = {
  icon: logoutIcon,
  text: 'Logout',
  partiallyActive: false,
}

const styles = {
  container: ({ width, isSidebarOpen }) => css`
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.15);
    background-color: ${colors.white};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    ${mixins.respondTo(misc.viewport['c-vp'])} {
      will-change: width;
      width: ${width}px;
    }

    z-index: ${misc.hamburgerMenuZIndex};

    ${mixins.respondToMax(misc.viewport['c-vp'])} {
      will-change: transform;
      width: ${MAX_SIDEBAR_WIDTH}px;
      transform: ${isSidebarOpen
        ? 'translateX(0)'
        : 'translateX(-110%)'};
    }

    ${mixins.transition()};
  `,
  logo: css`
    display: inline-block;
    margin-bottom: 40px;
    padding: 10px;
  `,
  links: css`
    margin-bottom: 0px;
  `,
  expander: css`
    margin-top: auto;
    flex-shrink: 0;
    padding: 10px;
    width: ${logoWidth}px;
    margin-left: auto;
  `,
  chevron: ({ isSidebarOpen }) => css`
    transform: ${isSidebarOpen ? 'rotateY(180deg)' : 'rotateY(0)'};
    fill: ${colors.grey};
    height: 15px;
    ${mixins.transition()}
  `,
  floatingButton: css`
    position: fixed;
    bottom: 10px;
    left: 10px;
    padding: 15px 10px;
    width: ${logoWidth}px;
    background-color: ${colors.yellow};
    z-index: ${misc.dropdownZindex};
    box-shadow: ${misc.boxShadow.default};
    border-radius: ${misc.borderRadius.md};
  `,
}

function Sidebar({ links, pathname }) {
  const ctr = useRef(null)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const scrollbarWidth = useScrollbarWidth()

  const closeSidebar = () => {
    setIsSidebarOpen(false)
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  const onResize = useCallback(
    debounce(() => {
      if (ctr.current && !isSidebarOpen) {
        const div = ctr.current
        if (div.scrollHeight > div.clientHeight) {
          document.getElementById(
            'content-js',
          ).style.marginLeft = `${logoWidth + scrollbarWidth}px`
          setIsOverflowing(true)
        } else {
          document.getElementById(
            'content-js',
          ).style.marginLeft = `${logoWidth}px`
          setIsOverflowing(false)
        }
      }
    }, 200),
    [isSidebarOpen],
  )

  useEvent('resize', onResize)
  useClickAway(ctr, closeSidebar)
  useEffect(() => {
    closeSidebar()
  }, [pathname])
  const getWidth = useMemo(() => {
    if (isSidebarOpen) return MAX_SIDEBAR_WIDTH
    if (isOverflowing) return logoWidth + scrollbarWidth
    return logoWidth
  }, [isSidebarOpen, isOverflowing, scrollbarWidth])

  // Toggle modal
  const { showWelcomeTourModal } = useWelcomeTourModalContext()
  const handleHelpLinkClick = event => {
    event.preventDefault()
    showWelcomeTourModal()
  }

  // Logout
  const { logout } = useLogout()

  return (
    <>
      <div
        ref={ctr}
        css={styles.container({
          isSidebarOpen,
          width: getWidth,
        })}
      >
        <Logo noRedirect width={100} css={styles.logo} />
        <div css={styles.links}>
          {links.map(link => (
            <SidebarLink
              key={link.id}
              {...link}
              iconPadding='15px'
              isSidebarOpen={isSidebarOpen}
            />
          ))}

          <SidebarLink
            {...settingsLink}
            isSidebarOpen={isSidebarOpen}
          />
          <SidebarLink
            {...userProfileLink}
            isSidebarOpen={isSidebarOpen}
          />
          <SidebarLink
            {...helpLink}
            onClick={handleHelpLinkClick}
            LinkType='a'
            href='#'
            isSidebarOpen={isSidebarOpen}
          />
          <SidebarLink
            {...logoutLink}
            onClick={logout}
            LinkType='a'
            href='#'
            isSidebarOpen={isSidebarOpen}
          />
        </div>
        <Button
          onClick={toggleSidebar}
          css={css`
            padding: 25px 10px;
            width: 100%;
          `}
        >
          <DoubleChevronRight
            className={isSidebarOpen ? 'ml-3 mr-auto' : ''}
            css={styles.chevron({ isSidebarOpen })}
          />
        </Button>
      </div>
      <Button
        className='d-sm-none d-flex align-items-center justify-content-center'
        css={styles.floatingButton}
        onClick={toggleSidebar}
      >
        <DoubleChevronRight
          css={css`
            ${styles.chevron({})};
            margin: auto;
          `}
        />
      </Button>
    </>
  )
}

Sidebar.defaultProps = {
  links: [],
}

Sidebar.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape(SidebarLinkPropTypes)),
  pathname: PropTypes.string.isRequired,
}

export default Sidebar
