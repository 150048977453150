import React from 'react'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import { spacer, colors } from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import { slideContainerStyles } from './WelcomeTourModal.styles'

const Slide = ({ gifURL, title, subtitle }) => (
  <div css={slideContainerStyles}>
    <div
      css={css`
        border-bottom: 1px solid ${colors.grey};
      `}
    >
      <img src={gifURL} width='100%' alt='gif' />
    </div>
    <div
      css={[
        spacer.padL80,
        spacer.padR80,
        spacer.padT30,
        spacer.padB10,
      ]}
    >
      <Title
        as='h2'
        color={colors.black}
        size='xxxLarge'
        css={spacer.mrB20}
      >
        {title}
      </Title>
      <Text
        as='h5'
        color={colors.black}
        size='large'
        css={spacer.mrB20}
      >
        {subtitle}
      </Text>
    </div>
  </div>
)

Slide.propTypes = {
  gifURL: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
}

export default Slide
