import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import Title from '@dqp/common/components/Title/Title'
import Text, { SuccessText } from '@dqp/common/components/Text/Text'
import { get } from 'lodash-es'
import NotificationWrapper from '../NotificationWrapper/NotificationWrapper'
import { useResendVerificationEmail } from '#api/auth'
import Stack from '#components/Stack/Stack'

function EmailVerificationNotification({ email }) {
  const [successMessage, setSuccessMessage] = useState('')
  const {
    resendVerificationEmail,
    loading,
  } = useResendVerificationEmail()

  const handleClick = () =>
    resendVerificationEmail({
      variables: { email },
    }).then(({ data }) => {
      const { message } = get(data, 'resendVerificationEmail', {})
      if (message) {
        setSuccessMessage(message)
      }
    })

  return (
    <NotificationWrapper bg={colors.yellow}>
      <Stack spacing={10}>
        <Title size='large' color={colors.black} css={spacer.mrB10}>
          Please verify your email address.
        </Title>
        <Text size='medium' color={colors.black} css={spacer.mrB10}>
          We’ve sent an email to <strong>{email}</strong>. Please
          check your inbox and click the link to verify.
        </Text>
        {successMessage ? (
          <SuccessText size='medium'>{successMessage}</SuccessText>
        ) : (
          <Button
            variant='black'
            size='small'
            isLoading={loading}
            onClick={handleClick}
          >
            RESEND EMAIL
          </Button>
        )}
      </Stack>
    </NotificationWrapper>
  )
}

EmailVerificationNotification.propTypes = {
  email: PropTypes.string.isRequired,
}

export default EmailVerificationNotification
