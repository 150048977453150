import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { getScrollTop } from 'get-scroll'
import { spacer, misc, mixins } from '@dqp/common/styles/utilities'
import DesktopNav from './DesktopNav'
import MobileNav from './MobileNav'
import Logo from '#components/Logo'

const HEADER_HEIGHT = 76 // this needs to be set otherwise it jumps around on initial load
const styles = {
  header: css`
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: ${misc.navZindex};
    box-shadow: 0 0 2px rgba(0, 0, 0, 0);
    height: ${HEADER_HEIGHT}px;
    &.sticky {
      top: 0;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    }
    &.nav-up {
      transform: translateY(-100%);
    }
    &.nav-down {
      transform: translateY(0);
    }
    ${mixins.transition()}
  `,
  innerCtr: css`
    padding: 10px 15px;
  `,
}

class PublicHeader extends React.Component {
  state = {
    primaryNavHeight: '',
  }

  delta = 50

  didScroll = false

  lastScrollTop = 0

  primaryNav = React.createRef()

  fauxNav = React.createRef()

  html = null

  body = null

  componentDidMount() {
    this.html = document.documentElement
    this.body = document.body
    this.adjustFauxHeaderHeight()
    setInterval(this.headerInterval, 60)
    window.addEventListener('resize', this.adjustFauxHeaderHeight)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    clearInterval(this.headerInterval)
    window.removeEventListener('resize', this.adjustFauxHeaderHeight)
    window.removeEventListener('scroll', this.handleScroll)
  }

  adjustFauxHeaderHeight = () => {
    this.setState(
      {
        primaryNavHeight: `${this.primaryNav.current.clientHeight}px`,
      },
      () => {
        this.fauxNav.current.style.height = this.state.primaryNavHeight
      },
    )
  }

  headerInterval = () => {
    if (this.didScroll) {
      this.hasScrolled()
      this.didScroll = false
    }
  }

  handleScroll = () => {
    this.didScroll = true
  }

  hasScrolled = () => {
    const st = getScrollTop()
    const documentHeight = Math.max(
      this.body.scrollHeight,
      this.body.offsetHeight,
      this.html.clientHeight,
      this.html.scrollHeight,
      this.html.offsetHeight,
    )

    // Make sure they scroll more than delta
    if (Math.abs(this.lastScrollTop - st) <= this.delta) {
      return ''
    }

    if (st > this.fauxNav.current.clientHeight) {
      this.primaryNav.current.classList.add('sticky')
    } else {
      this.primaryNav.current.classList.remove('sticky')
    }

    // If they scrolled down and are past the navbar, add class .nav-up.
    if (
      st > this.lastScrollTop &&
      st > this.primaryNav.current.clientHeight
    ) {
      // Scroll Down
      this.primaryNav.current.classList.add('nav-up')
      this.primaryNav.current.classList.remove('nav-down')
    } else {
      // Scroll Up
      // eslint-disable-next-line no-lonely-if
      if (st + window.innerHeight < documentHeight) {
        this.primaryNav.current.classList.add('nav-down')
        this.primaryNav.current.classList.remove('nav-up')
      }
    }

    this.adjustFauxHeaderHeight()

    this.lastScrollTop = st

    return ''
  }

  render() {
    const {
      headerColor,
      routes,
      loginRoute,
      signupRoute,
    } = this.props
    return (
      <>
        <div
          ref={this.fauxNav}
          style={{
            backgroundColor: headerColor,
            height: HEADER_HEIGHT,
          }}
        />
        <header
          style={{ backgroundColor: headerColor }}
          ref={this.primaryNav}
          css={styles.header}
        >
          <div
            css={styles.innerCtr}
            className='container d-flex align-items-center justify-content-between'
          >
            <Logo css={spacer.mrR40} />
            <div
              className='d-none d-lg-flex align-items-center'
              css={css`
                flex-grow: 1;
              `}
            >
              <DesktopNav
                routes={routes}
                signupRoute={signupRoute}
                loginRoute={loginRoute}
              />
            </div>
            <div className='d-block d-lg-none'>
              <MobileNav />
            </div>
          </div>
        </header>
      </>
    )
  }
}

PublicHeader.propTypes = {
  headerColor: PropTypes.string.isRequired,
}

export default PublicHeader
// const HeaderNew = () => {
//   const [primaryNavHeight, setPrimaryNavHeight] = useState(90)
//   const primaryNav = useRef(null)
//   const fauxNav = useRef(null)
//   const delta = 50
//   const didScroll = useRef(false)
//   const lastScrollTop = useRef(0)
//   const handleScroll = () => {
//     didScroll.current = true
//   }
//   const adjustFauxHeaderHeight = () => {
//     const { clientHeight } = primaryNav.current
//     setPrimaryNavHeight(clientHeight)
//     fauxNav.current.style.height = clientHeight
//   }
//   useEffect(() => {
//     const html = document.documentElement
//     const { body } = document
//     window.addEventListener('resize', adjustFauxHeaderHeight)
//     window.addEventListener('scroll', handleScroll)
//   }, [])
// }
