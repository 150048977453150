import React from 'react'
import { Router as BrowserRouter } from '@reach/router'
import { colors } from '@dqp/common/styles/utilities'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import DefaultRoute from './DefaultRoute'
import routes from '#globals/routes'
import LogIn from '#containers/LogIn'
import ManageSubject from '#containers/ManageSubject'
import MyAccount from '#containers/MyAccount'
import SubjectDashboard from '#containers/SubjectDashboard'
import Questions from '#containers/Questions'
import OverviewDashboard from '#containers/OverviewDashboard'
import SignUp from '#containers/SignUp'
import VerifyEmail from '#containers/VerifyEmail'
import ResetPassword from '#containers/ResetPassword'
import UpdateForgottenPassword from '#containers/UpdateForgottenPassword'
import PrivateNotFound from '#containers/NotFound/PrivateNotFound'
import PublicNotFound from '#containers/NotFound/PublicNotFound'

const Router = () => {
  return (
    <BrowserRouter>
      <PrivateRoute
        path={routes.app.to}
        component={ManageSubject}
        title='Manage Subjects'
      />
      <PrivateRoute
        path={routes.account.to}
        component={MyAccount}
        title='My Account'
      />
      <PrivateRoute
        path={routes.subjectDashboard.to}
        component={SubjectDashboard}
        title='Subject Dashboard'
      />
      <PrivateRoute
        path={routes.questionDashboard.to}
        component={Questions}
        title='Questions Dashboard'
      />
      <PrivateRoute
        path={routes.overviewDashboard.to}
        component={OverviewDashboard}
        title='Overview'
      />
      <PrivateRoute
        title='404: Not Found'
        headerColor={colors.red}
        path={routes.privateNotFound.to}
        component={PrivateNotFound}
      />
      <PublicRoute
        title='Login'
        headerColor={colors.purple}
        path={routes.login.to}
        component={LogIn}
      />
      <PublicRoute
        title='Signup'
        headerColor={colors.yellow}
        path={routes.signup.to}
        component={SignUp}
      />
      <PublicRoute
        title='Verify Email'
        headerColor={colors.purple}
        path={routes.verifyemail.to}
        component={VerifyEmail}
      />
      <PublicRoute
        title='Reset Password'
        headerColor={colors.yellow}
        path={routes.resetpassword.to}
        component={ResetPassword}
      />
      <PublicRoute
        title='Update Password'
        headerColor={colors.yellow}
        path={routes.updatepassword.to}
        component={UpdateForgottenPassword}
      />
      <PublicRoute
        title='404: Not Found'
        headerColor={colors.red}
        path={routes.publicNotFound.to}
        component={PublicNotFound}
      />
      <DefaultRoute default />
    </BrowserRouter>
  )
}

export default Router
