import React, { useEffect } from 'react'
import { get } from 'lodash-es'
import {
  useNotificationContext,
  notificationActionTypes,
  notificationTypes,
} from '#context/NotificationsContext'
import { useUserContext } from '#context/UserContext'
import EmailVerificationNotification from '#components/Notifications/EmailVerificationNotification'
import PaymentInformationNotification from '#components/Notifications/PaymentInformationNotification'
import Stack from '#components/Stack/Stack'

export const NotificationsContainer = () => {
  const {
    notificationsMap,
    notificationsDispatch,
  } = useNotificationContext()
  const { user } = useUserContext()

  useEffect(() => {
    const emailVerifiedAt = get(user, 'user.email_verified_at')
    if (emailVerifiedAt == null) {
      notificationsDispatch({
        type: notificationActionTypes.SHOW_NOTIFICATION,
        payload: notificationTypes.VERIFY_EMAIL,
      })
    } else {
      notificationsDispatch({
        type: notificationActionTypes.CLOSE_NOTIFICATION,
        payload: notificationTypes.VERIFY_EMAIL,
      })
    }
  }, [user, notificationsDispatch])

  return (
    <Stack className='container-md' spacing={20}>
      {notificationsMap[notificationTypes.VERIFY_EMAIL] && (
        <div>
          <EmailVerificationNotification email={user.user.email} />
        </div>
      )}
      {notificationsMap[notificationTypes.PAYMENT_INFORMATION] && (
        <div>
          <PaymentInformationNotification email={user.user.email} />
        </div>
      )}
    </Stack>
  )
}
