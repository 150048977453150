import Loadable from 'react-loadable'
import Loader from '#components/Loader/Loader'

export default Loadable({
  loader: () =>
    import(
      './SubjectDashboard' /* webpackChunkName: "SubjectDashboard" */
    ),
  loading: Loader,
})
