import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Controls from './CarouselControls'
import 'slick-carousel/slick/slick.css'

const Carousel = React.forwardRef(({ settings, ...props }, ref) => (
  <Slider {...settings} {...props} ref={ref}>
    {props.children}
  </Slider>
))

Carousel.propTypes = {
  settings: PropTypes.object,
  children: PropTypes.node.isRequired,
}

Carousel.defaultProps = {
  settings: {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Controls.NextArrow />,
    prevArrow: <Controls.PrevArrow />,
  },
}

export default Carousel
