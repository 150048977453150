import React, { useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import { misc, colors, mixins } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import { Cross } from '@dqp/common/components/icons'
import breakPoints from '@dqp/common/styles/helpers/breakpoints'
import { useClickAway, useEvent } from 'react-use'
import { debounce } from 'lodash-es'
import PublicHamburgerMenu from './PublicHamburgerMenu'
import Logo from '#components/Logo'

const MENU_WIDTH = `360px`
const styles = {
  container: css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    will-change: transform;
    z-index: ${misc.hamburgerMenuZIndex};
    background-color: ${colors.yellow};
    ${mixins.transition()};
  `,
}
function HamburgerMenu({ isOpen, closeMenu }) {
  const ctr = useRef(null)
  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(ctr.current)
    } else {
      enableBodyScroll(ctr.current)
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isOpen])
  useClickAway(ctr, closeMenu)
  const setHeight = () => {
    if (!ctr.current) return
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    ctr.current.style.height = `${height}px`
  }
  const onResize = useCallback(debounce(setHeight, 200), [])
  useEvent('resize', onResize)
  useEffect(setHeight, [])
  return (
    <div
      ref={ctr}
      css={css`
        ${styles.container};
        ${breakPoints({
          width: [`100%`, `100%`, `100%`, MENU_WIDTH, MENU_WIDTH],
        })}
        transform: ${isOpen ? `translateX(0)` : `translateX(100%)`};
      `}
    >
      <div
        className='container d-flex justify-content-between align-items-center'
        css={css`
          padding: 10px 15px;
          height: 76px;
        `}
      >
        <Logo />
        <Button onClick={closeMenu} aria-label='close-menu'>
          <Cross />
        </Button>
      </div>
      <div className='container'>
        <PublicHamburgerMenu closeMenu={closeMenu} />
      </div>
    </div>
  )
}

HamburgerMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
  // isLoggedIn: PropTypes.bool,
}

export default HamburgerMenu
