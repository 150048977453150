import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import { colors } from '@dqp/common/styles/utilities'
import Router from '#components/Router'
import '#utils/logging'

const styles = {
  initialPageLoader: css`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${colors.purple};
  `,
}

const Index = () => {
  const [isClient, setIsClient] = useState(true)

  useEffect(() => {
    setIsClient(false)
  }, [])

  return (
    <>
      {isClient ? <div css={styles.initialPageLoader} /> : <Router />}
    </>
  )
}

export default Index
