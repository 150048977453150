import React from 'react'
import PropTypes from 'prop-types'
import Title from '@dqp/common/components/Title/Title'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import Text from '@dqp/common/components/Text/Text'
import ModalContainer from '#components/ModalContainer/ModalContainer'
import spaceShip from '#images/spaceShip.svg'

function ErrorModal({ isOpen, closeModal, text }) {
  return (
    <ModalContainer isOpen={isOpen} closeModal={closeModal}>
      <div className='d-flex flex-column align-items-center text-center'>
        <img src={spaceShip} alt='spaceShip' css={spacer.mrB20} />
        <Title
          color={colors.black}
          size='xxxLarge'
          css={[spacer.mrB10]}
        >
          Whoops!
        </Title>
        <Text color={colors.grey} css={[spacer.mrB20]}>
          {text}
        </Text>

        <Button
          size='medium'
          variant='blue'
          css={spacer.mrB10}
          onClick={closeModal}
        >
          CLOSE
        </Button>
      </div>
    </ModalContainer>
  )
}

ErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

export default ErrorModal
