import React from 'react'
import { spacer, colors } from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import Link, { ExternalLink } from '@dqp/common/components/Link/Link'
import SocialMediaLinks from './SocialMediaLinks'
import Logo from '#components/Logo'
import { footerRoutes } from '#globals/routes'

function DesktopFooter() {
  return (
    <div className='container'>
      <div className='row justify-content-between'>
        <div className='col-lg-2'>
          <Logo css={spacer.mrR30} />
        </div>
        <div className='col-lg-8'>
          <div className='row'>
            {footerRoutes.map((route, i) => (
              <div
                className='col-lg-4 col-sm-6 d-flex'
                key={route.title}
              >
                <div className='d-flex flex-column'>
                  <Text
                    weight='bold'
                    color={colors.black}
                    size='xLarge'
                    css={spacer.mrB20}
                  >
                    {route.title}
                  </Text>

                  {route.routeMap.map(r => {
                    const Tag = r.external ? ExternalLink : Link
                    return (
                      <Text
                        color={colors.black}
                        size='medium'
                        key={r.text}
                      >
                        <Tag
                          to={r.to}
                          {...(r.external && {
                            target: '_blank',
                          })}
                        >
                          {r.text}
                        </Tag>
                      </Text>
                    )
                  })}
                  {i === footerRoutes.length - 1 && (
                    <SocialMediaLinks />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DesktopFooter
