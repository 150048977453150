import React from 'react'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import { misc, colors, spacer } from '@dqp/common/styles/utilities'
import { Cross } from '@dqp/common/components/icons'
import Button from '@dqp/common/components/Button/Button'

const styles = {
  container: ({ bg }) => css`
    position: relative;
    border-radius: ${misc.borderRadius.md};
    box-shadow: ${misc.boxShadow.default};
    position: relative;
    background-color: ${bg};
    ${spacer.padLR30};
    ${spacer.padBT30};
  `,
  closeButton: css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 0;
    right: 15px;
    top: 15px;
  `,
  crossIcon: css`
    width: 18px;
    height: 18px;
  `,
}

function NotificationWrapper({
  bg,
  children,
  isDismissible,
  onDismiss,
}) {
  return (
    <div css={styles.container({ bg })}>
      {children}
      {isDismissible && (
        <Button onClick={onDismiss} css={styles.closeButton}>
          <Cross css={styles.crossIcon} />
        </Button>
      )}
    </div>
  )
}

NotificationWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  bg: PropTypes.string,
  onDismiss: PropTypes.func,
  isDismissible: PropTypes.bool,
}

NotificationWrapper.defaultProps = {
  bg: colors.yellow,
  onDismiss: () => {},
  isDismissible: false,
}
export default NotificationWrapper
