import React, { useEffect, useCallback } from 'react'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import NotificationWrapper from '../NotificationWrapper/NotificationWrapper'
import Stack from '#components/Stack/Stack'
import {
  useNotificationContext,
  notificationActionTypes,
  notificationTypes,
} from '#context/NotificationsContext'

const TIMEOUT_DURATION = 10000
function PaymentInformationNotification() {
  const { notificationsDispatch } = useNotificationContext()
  const closeNotification = useCallback(
    () =>
      notificationsDispatch({
        type: notificationActionTypes.CLOSE_NOTIFICATION,
        payload: notificationTypes.PAYMENT_INFORMATION,
      }),
    [notificationsDispatch],
  )

  useEffect(() => {
    const id = setTimeout(closeNotification, TIMEOUT_DURATION)
    return () => clearTimeout(id)
  }, [closeNotification])

  return (
    <NotificationWrapper
      bg={colors.yellow}
      isDismissible
      onDismiss={closeNotification}
    >
      <Stack spacing={10}>
        <Title size='large' color={colors.black} css={spacer.mrB10}>
          Payment Verification in progress.
        </Title>
        <Text size='medium' color={colors.black} css={spacer.mrB10}>
          If you dont see your Subcription within a few seconds,
          Please refresh your browser
        </Text>
      </Stack>
    </NotificationWrapper>
  )
}

PaymentInformationNotification.propTypes = {}

export default PaymentInformationNotification
