import React, { useContext, createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

const NotificationsStateContext = createContext()
const NotificationsDispatchContext = createContext()

export const notificationTypes = {
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  PAYMENT_INFORMATION: 'PAYMENT_INFORMATION',
}

export const notificationActionTypes = {
  SHOW_NOTIFICATION: 'SET_NOTIFICATION',
  CLOSE_NOTIFICATION: 'CLOSE_NOTIFICATION',
}

const initialState = {
  // [notificationType]: Boolean
  notificationsMap: {},
}

const notificationReducer = (state, action) => {
  const { payload, type } = action
  switch (type) {
    case notificationActionTypes.SHOW_NOTIFICATION:
      return {
        ...state,
        notificationsMap: {
          ...state.notificationsMap,
          [payload]: true,
        },
      }
    case notificationActionTypes.CLOSE_NOTIFICATION:
      return {
        ...state,
        notificationsMap: {
          ...state.notificationsMap,
          [payload]: false,
        },
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    notificationReducer,
    initialState,
  )
  return (
    <NotificationsStateContext.Provider value={state}>
      <NotificationsDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationsDispatchContext.Provider>
    </NotificationsStateContext.Provider>
  )
}

const useNotificationState = () =>
  useContext(NotificationsStateContext)

const useNotificationDispatch = () =>
  useContext(NotificationsDispatchContext)

export const useNotificationContext = () => {
  return {
    ...useNotificationState(),
    notificationsDispatch: useNotificationDispatch(),
  }
}
NotificationsProvider.propTypes = {
  children: PropTypes.any.isRequired,
}
