import React, { useLayoutEffect } from 'react'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import { LinkBtn } from '@dqp/common/components/Button/Button'
import routes from '#globals/routes'
import { useThemeColor } from '#context/ThemeColorContext'

function PrivateNotFound() {
  const { setThemeColor } = useThemeColor()
  useLayoutEffect(() => {
    setThemeColor(colors.darkRed)
  }, [setThemeColor])
  return (
    <section>
      <div style={{ height: 50 }} />
      <div className='container-md relative'>
        <div className='row justify-content-center'>
          <div className='col-lg-6 text-center'>
            <Title
              as='h1'
              size='xxxxLarge'
              color={colors.black}
              weight='bold'
              css={spacer.mrB20}
            >
              Whoops! This page doesn’t exist.
            </Title>
            <LinkBtn
              size='large'
              variant='black'
              inline
              to={routes.app.to}
            >
              TAKE ME HOME
            </LinkBtn>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PrivateNotFound
