import React from 'react'
import { useLocation } from '@reach/router'
import Sidebar from './Sidebar'
import settingsIcon from '#images/settingsIcon.svg'
import { useGetProfiles } from '#api/getProfiles'
import routes from '#globals/routes'

const SidebarContainer = () => {
  const { profiles, loading } = useGetProfiles()
  const location = useLocation()
  let links = []

  if (loading) {
    links = []
  }
  if (profiles && profiles.getProfiles) {
    const transformedLinks = profiles.getProfiles.map(profile => ({
      id: profile.id,
      to: routes.overviewDashboard.getPathFromParams({
        profileId: profile.id,
      }),
      icon: profile.icon ? profile.icon.icon : settingsIcon,
      text: profile.name,
    }))
    links = transformedLinks
  }
  return <Sidebar pathname={location.pathname} links={links} />
}

export default SidebarContainer
