import React from 'react'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import { spacer } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'

const TourControls = ({
  isFirstSlide,
  isLastSlide,
  gotoNextSlide,
  closeModal,
}) => {
  const getNextButtonText = () => {
    if (isFirstSlide) {
      return 'Take 1 minute tour'
    }
    if (isLastSlide) {
      return 'Get started'
    }
    return 'Next'
  }

  return (
    <div
      className='d-flex flex-column align-items-center'
      css={css`
        ${spacer.padT20};
        ${spacer.padB40};
      `}
    >
      <Button
        size='medium'
        variant='blue'
        css={spacer.mrB10}
        onClick={gotoNextSlide}
      >
        {getNextButtonText()}
      </Button>
      <Button
        onClick={e => {
          e.preventDefault()
          closeModal()
        }}
        css={css`
          pointer-events: ${isFirstSlide ? 'initial' : 'none'};
          opacity: ${isFirstSlide ? 1 : 0};
        `}
      >
        SKIP FOR NOW
      </Button>
    </div>
  )
}

TourControls.propTypes = {
  isFirstSlide: PropTypes.bool.isRequired,
  isLastSlide: PropTypes.bool.isRequired,
  gotoNextSlide: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default TourControls
