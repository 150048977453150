import { navigate } from '@reach/router'
import { useUserContext } from '#context/UserContext'
import routes from '#globals/routes'

const DefaultRoute = () => {
  const { user } = useUserContext()
  if (!user) {
    navigate(routes.publicNotFound.to)
    return null
  }
  navigate(routes.privateNotFound.to)
  return null
}

export default DefaultRoute
