import React from 'react'
import { css } from '@emotion/core'
import { breakpoints, colors } from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import Link, {
  ExternalLink,
  ExternalLinkBtn,
} from '@dqp/common/components/Link/Link'
import { LinkBtn } from '@dqp/common/components/Button/Button'

const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  `,

  links: css`
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      ${breakpoints({
        marginRight: [0, 0, 15, 15, 30],
        '&:last-child': {
          marginRight: [0],
        },
      })}
    }
  `,
  buttons: css`
    margin-left: auto;
    ${LinkBtn} {
      ${breakpoints({
        marginRight: [0, 0, 15, 15, 30],
        '&:last-child': {
          marginRight: [0],
        },
      })}
    }
  `,
}

function DesktopNav({ routes, signupRoute, loginRoute }) {
  const activeStyle = { borderColor: colors.black }
  const textStyleProps = {
    as: 'span',
    weight: 'bold',
    color: colors.black,
  }

  const LoginTag = loginRoute.external ? ExternalLinkBtn : LinkBtn
  const SignupTag = signupRoute.external ? ExternalLinkBtn : LinkBtn
  return (
    <div css={styles.container}>
      <ul css={styles.links}>
        {routes.map(route => {
          const Tag = route.external ? ExternalLink : Link
          return (
            <li key={route.text}>
              <Text {...textStyleProps}>
                <Tag activeStyle={activeStyle} to={route.to}>
                  {route.text}
                </Tag>
              </Text>
            </li>
          )
        })}
      </ul>
      <div css={styles.buttons}>
        <LoginTag
          size='medium'
          variant='black'
          inline
          to={loginRoute.to}
        >
          LOG IN
        </LoginTag>
        <SignupTag
          size='medium'
          variant='black'
          inline
          to={signupRoute.to}
        >
          TRY FOR FREE
        </SignupTag>
      </div>
    </div>
  )
}

export default DesktopNav
