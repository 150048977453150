import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

export const GET_PROFILES_QUERY = gql`
  query getProfiles {
    getProfiles {
      id
      name
      icon {
        id
        icon
      }
    }
  }
`

export const useGetProfiles = () => {
  const { data: profiles, loading, error } = useQuery(
    GET_PROFILES_QUERY,
  )
  return { profiles, loading, error }
}
