import React, { useRef, useState } from 'react'
import { css } from '@emotion/core'
import Button from '@dqp/common/components/Button/Button'
import { colors } from '@dqp/common/styles/utilities'
import { ExternalLink } from '@dqp/common/components/Link/Link'
import Carousel from './Carousel'
import Controls from './CarouselControls'
import Slide from './Slide'
import TourControls from './TourControls'
import {
  modalContainerStyles,
  carouselDotsStyles,
} from './WelcomeTourModal.styles'
import ModalContainer from '#components/ModalContainer/ModalContainer'
import { useUserContext } from '#context/UserContext'
import { useWelcomeTourModalContext } from '#context/WelcomeTourModalContext'
import { companyEmail } from '#globals/constants'
import slideWelcome from '#images/welcome-tour/slide-welcome.jpg'
import slideAddSubject from '#images/welcome-tour/slide-add-subject.jpg'
import slideViewProfile from '#images/welcome-tour/slide-view-profile.jpg'
import slideAnswerQuestions from '#images/welcome-tour/slide-answer-questions.jpg'
import slideManageSubjects from '#images/welcome-tour/slide-manage-subjects.jpg'
import slideAccount from '#images/welcome-tour/slide-account.jpg'
import slideLogout from '#images/welcome-tour/slide-logout.jpg'
import slideMenu from '#images/welcome-tour/slide-menu.jpg'
import slideRetakeTour from '#images/welcome-tour/slide-retake-tour.jpg'
import slideEnd from '#images/welcome-tour/slide-end.jpg'

// import PropTypes from 'prop-types';

const CAROUSEL_SETTINGS = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <Controls.NextArrow />,
  prevArrow: <Controls.PrevArrow />,
  appendDots: dots => <ul css={carouselDotsStyles}>{dots}</ul>,
  customPaging: i => <Button color={colors.black}>{i}</Button>,
}

const WelcomeTourModal = () => {
  const {
    isOpen,
    closeWelcomeTourModal,
  } = useWelcomeTourModalContext()
  const { user } = useUserContext()
  const { first_name } = user.user

  const lastSlideSubtitle = (
    <>
      That&apos;s the end of the tour! If you face any issues, please
      contact{' '}
      <ExternalLink
        to={`mailto:${companyEmail}`}
        hoverColor='black'
        borderColor={colors.charcoal}
      >
        {companyEmail}
      </ExternalLink>
      . We are always happy to help.
    </>
  )
  const slides = [
    {
      title: `Welcome to your dashboard, ${first_name}!`,
      subtitle:
        'Here you can access all your subjects, subscriptions and stats. Take our 1 minute tour to learn how it all works.',
      gifURL: slideWelcome,
    },
    {
      title: 'Add a Subject',
      subtitle:
        'Begin by adding a subject, creating a user profile and choosing your plan.',
      gifURL: slideAddSubject,
    },
    {
      title: 'View Profile',
      subtitle:
        "You can now access the new user's profile by clicking their icon in the menu on  the left. Here you can view relevant statistics and get an overview of their progress.",
      gifURL: slideViewProfile,
    },
    {
      title: 'Answer questions and watch tutorials',
      subtitle:
        'On this page, your child can see an overview of available questions. Once they have attempted a question, they will be invited to watch a relevant tutorial.',
      gifURL: slideAnswerQuestions,
    },
    {
      title: 'Manage Subjects',
      subtitle:
        'Click this icon on the left to add or reset subjects. You can also update user profiles  and upgrade your access if you wish.',
      gifURL: slideManageSubjects,
    },
    {
      title: 'My Account',
      subtitle: 'Click this icon to update your personal details.',
      gifURL: slideAccount,
    },
    {
      title: 'Log Out',
      subtitle: 'Click this icon to log out of your dashboard.',
      gifURL: slideLogout,
    },
    {
      title: 'Expand the menu',
      subtitle:
        'Click this arrow to expand the navigation menu on the left.',
      gifURL: slideMenu,
    },
    {
      title: 'Retake the tour',
      subtitle: 'Click this icon to watch this tour again.',
      gifURL: slideRetakeTour,
    },
    {
      title: 'The End!',
      subtitle: lastSlideSubtitle,
      gifURL: slideEnd,
    },
  ]
  const [currentSlide, setCurrentSlide] = useState(0)
  const carouselRef = useRef(null)
  const isLastSlide = currentSlide === slides.length - 1
  const isFirstSlide = currentSlide === 0
  const gotoNextSlide = () => {
    if (isLastSlide) {
      closeWelcomeTourModal()
    } else if (carouselRef.current) carouselRef.current.slickNext()
  }
  return (
    <ModalContainer
      isOpen={isOpen}
      closeModal={closeWelcomeTourModal}
      modalContainerStyles={modalContainerStyles({
        isFirstSlide,
        isLastSlide,
      })}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
      containerStyles={css`
        padding: 0 !important;
        overflow: hidden;
      `}
      crossBtnStyles={css`
        /* position: fixed; */
        fill: white;
        border: 1px solid white;
        padding: 5px;
        background-color: rgba(0, 0, 0, 0.4);
      `}
    >
      <Carousel
        ref={carouselRef}
        beforeChange={(_, next) => setCurrentSlide(next)}
        settings={CAROUSEL_SETTINGS}
      >
        {slides.map(({ gifURL, title, subtitle }) => (
          <Slide
            key={title}
            gifURL={gifURL}
            title={title}
            subtitle={subtitle}
          />
        ))}
      </Carousel>
      <TourControls
        isFirstSlide={isFirstSlide}
        closeModal={closeWelcomeTourModal}
        isLastSlide={isLastSlide}
        gotoNextSlide={gotoNextSlide}
      />
    </ModalContainer>
  )
}

WelcomeTourModal.propTypes = {}

export default WelcomeTourModal
