import React from 'react'

const useModal = initialState => {
  const [isOpen, setIsOpen] = React.useState(initialState || false)
  return {
    isOpen,
    openModal: () => setIsOpen(true),
    closeModal: () => setIsOpen(false),
    toggleModal: () => setIsOpen(s => !s),
  }
}

export default useModal
