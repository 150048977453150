import { useMutation, useApolloClient } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { useUserContext } from '#context/UserContext'

const TOKENS_FRAGMENT = gql`
  fragment TOKENS_FRAGMENT on AuthPayload {
    access_token
    user {
      id
      first_name
      last_name
      email
      email_verified_at
      watched_introduction
    }
  }
`
const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(input: { username: $email, password: $password }) {
      ...TOKENS_FRAGMENT
    }
  }
  ${TOKENS_FRAGMENT}
`

const FORGOT_PASSWORD_MUTATION = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(input: { email: $email }) {
      message
      status
    }
  }
`

const UPDATE_FORGOTTEN_PASSWORD_MUTATION = gql`
  mutation updateForgottenPassword(
    $email: String!
    $token: String!
    $password: String!
    $password_confirmation: String!
  ) {
    updateForgottenPassword(
      input: {
        email: $email
        token: $token
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      status
      message
    }
  }
`
const REGISTER_MUTATION = gql`
  mutation register(
    $first_name: String!
    $last_name: String!
    $email: String!
    $password: String!
    $password_confirmation: String!
  ) {
    register(
      input: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      ...TOKENS_FRAGMENT
    }
  }
  ${TOKENS_FRAGMENT}
`

const RESEND_VERIFICATION_EMAIL_MUTATION = gql`
  mutation resendVerificationEmail($email: String!) {
    resendVerificationEmail(input: { email: $email }) {
      message
    }
  }
`
const VERIFY_EMAIL_MUTATION = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(input: { token: $token }) {
      ...TOKENS_FRAGMENT
    }
  }
  ${TOKENS_FRAGMENT}
`

export const useLogin = () => {
  const { setUser } = useUserContext()
  const [login, { data, loading, error }] = useMutation(
    LOGIN_MUTATION,
    {
      onCompleted: response => {
        if (response.login) {
          setUser(response.login)
        }
      },
    },
  )

  return { login, data, loading, error }
}

export const useRegister = () => {
  const { setUser } = useUserContext()
  const [register, { data, loading, error }] = useMutation(
    REGISTER_MUTATION,
    {
      onCompleted: response => {
        if (response.register) {
          setUser(response.register)
        }
      },
    },
  )
  return { register, data, loading, error }
}

export const useForgotPassword = () => {
  const [forgotPassword, { data, loading, error }] = useMutation(
    FORGOT_PASSWORD_MUTATION,
  )
  return { forgotPassword, data, loading, error }
}

export const useUpdateForgottenPassword = () => {
  const [
    updateForgottenPassword,
    { data, loading, error },
  ] = useMutation(UPDATE_FORGOTTEN_PASSWORD_MUTATION)
  return { updateForgottenPassword, data, loading, error }
}

export const useLogout = () => {
  const { setUser } = useUserContext()
  const client = useApolloClient()
  return {
    logout: () => {
      setUser(null)
      client.resetStore()
    },
  }
}

export const useResendVerificationEmail = () => {
  const [
    resendVerificationEmail,
    { data, loading, error },
  ] = useMutation(RESEND_VERIFICATION_EMAIL_MUTATION)
  return { resendVerificationEmail, data, error, loading }
}

export const useVerifyEmail = () => {
  const { setUser } = useUserContext()
  const [verifyEmail, { data, loading, error }] = useMutation(
    VERIFY_EMAIL_MUTATION,
    {
      onCompleted: response => {
        if (response.verifyEmail) {
          setUser(response.verifyEmail)
        }
      },
    },
  )
  return { verifyEmail, data, error, loading }
}
