import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import PublicLayout from './Layout/PublicLayout'
import Helmet from './Helmet'
import routes from '#globals/routes'
import { useUserContext } from '#context/UserContext'

const PublicRoute = ({
  component: Component,
  title,
  path,
  headerColor,
  ...rest
}) => {
  const { user } = useUserContext()
  if (path === routes.publicNotFound.to) {
    // console.info('do nothing cuz on 404')
  } else if (user) {
    if (user.user.email_verified_at) {
      navigate(routes.app.to)
      return null
    }
    if (path === routes.verifyemail.to) {
      // console.info('do nothing cuz on verifyemail page')
    } else {
      navigate(routes.app.to)
      return null
    }
  } else {
    // console.info('do nothing cuz there is no user')
  }

  return (
    <PublicLayout headerColor={headerColor}>
      <Helmet title={title} />
      <Component {...rest} />
    </PublicLayout>
  )
}

PublicRoute.propTypes = {
  component: PropTypes.any.isRequired,
  headerColor: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default PublicRoute
