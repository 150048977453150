import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import SuccessModal from '#components/Modals/SuccessModal'
import ErrorModal from '#components/Modals/ErrorModal'

const SuccessOrErrorModalContext = createContext()

export const useSuccessOrErrorModalContext = () => {
  const { showSuccessModal, showErrorModal } = useContext(
    SuccessOrErrorModalContext,
  )
  return { showErrorModal, showSuccessModal }
}

const initialState = {
  successText: '',
  errorText: '',
}
const SuccessOrErrorModalProvider = ({ children }) => {
  const [state, setState] = useState(initialState)
  const showSuccessModal = useCallback(message => {
    setState({ successText: message, errorText: '' })
  }, [])

  const showErrorModal = useCallback(message => {
    setState({ successText: '', errorText: message })
  }, [])

  const value = useMemo(
    () => ({ showSuccessModal, showErrorModal }),
    [showErrorModal, showSuccessModal],
  )
  const closeModal = useCallback(() => setState(initialState), [])
  return (
    <SuccessOrErrorModalContext.Provider value={value}>
      {children}
      <SuccessModal
        text={state.successText}
        isOpen={!!state.successText}
        closeModal={closeModal}
      />
      <ErrorModal
        text={state.errorText}
        isOpen={!!state.errorText}
        closeModal={closeModal}
      />
    </SuccessOrErrorModalContext.Provider>
  )
}
SuccessOrErrorModalProvider.propTypes = {
  children: PropTypes.any.isRequired,
}
export default SuccessOrErrorModalProvider
