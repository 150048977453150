import React from 'react'
import 'lazysizes/plugins/attrchange/ls.attrchange'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import Imgix, { Picture, Source, PublicConfigAPI } from 'react-imgix'
import { breakpoints } from '../styles/utilities'
import { baseImgixURL } from '../lib/constants'

PublicConfigAPI.disableWarning('fallbackImage')

const Image = React.forwardRef(function Image(
  { className, sizes, image, alt, absolute = true, lazy },
  ref,
) {
  const sourceProps = lazy
    ? {
        className: 'lazyload',
        attributeConfig: {
          src: 'data-src',
          srcSet: 'data-srcset',
          sizes: 'data-sizes',
        },
      }
    : {}
  const imgProps = lazy
    ? {
        className: 'lazyload',
        attributeConfig: {
          src: 'data-src',
        },
      }
    : {}

  return (
    <div
      ref={ref}
      className={className}
      css={css`
        pointer-events: none;
        position: ${absolute === true ? 'absolute' : 'relative'};
      `}
    >
      <Picture>
        {/* large and above */}
        <Source
          {...sourceProps}
          src={`${baseImgixURL}${image}.webp`}
          width={sizes.lg.width}
          height={sizes.lg.height}
          htmlAttributes={{ media: '(min-width: 992px)' }}
        />
        {/* medium vp */}
        <Source
          {...sourceProps}
          src={`${baseImgixURL}${image}.webp`}
          width={sizes.md.width}
          height={sizes.md.height}
          htmlAttributes={{
            media: '(min-width: 768px) and (max-width: 991px)',
          }}
        />
        {sizes.sm ? (
          <>
            <Source
              // upto medium vp
              {...sourceProps}
              src={`${baseImgixURL}${image}.webp`}
              width={sizes.sm.width}
              height={sizes.sm.height}
              htmlAttributes={{
                media: '(min-width: 576px) and (max-width: 767px)',
              }}
            />
            <Source
              // upto small
              {...sourceProps}
              src={`${baseImgixURL}${image}.webp`}
              width={2}
              height={2}
              htmlAttributes={{
                media: '(min-width: 0px) and (max-width: 575px)',
              }}
            />
          </>
        ) : (
          <Source
            // upto medium
            {...sourceProps}
            src={`${baseImgixURL}${image}.webp`}
            width={2}
            height={2}
            htmlAttributes={{
              media: '(min-width: 0px) and (max-width: 767px)',
            }}
          />
        )}
        <Imgix
          {...imgProps}
          src={`${baseImgixURL}${image}.webp`}
          disableSrcSet
          htmlAttributes={{ alt }}
          css={css`
            ${breakpoints({
              width: [
                0,
                sizes.sm && sizes.sm.width ? sizes.sm.width : 0,
                sizes.md && sizes.md.width ? sizes.md.width : 0,
                sizes.lg && sizes.lg.width ? sizes.lg.width : 0,
                sizes.lg && sizes.lg.width ? sizes.lg.width : 0,
              ],
            })}
          `}
        />
      </Picture>
    </div>
  )
})

Image.propTypes = {
  absolute: PropTypes.bool,
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  sizes: PropTypes.shape({
    lg: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    md: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    sm: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }),
  alt: PropTypes.string.isRequired,
  lazy: PropTypes.bool,
}
Image.defaultProps = {
  absolute: true,
  lazy: true,
  className: '',
  sizes: {},
}
export default Image
