import React from 'react'
import { css } from '@emotion/core'
import {
  colors,
  breakpoints,
  spacer,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import { LinkBtn } from '@dqp/common/components/Button/Button'
import Image from '@dqp/common/components/Image'
import Wave from '@dqp/common/components/Wave/Wave'
import routes from '#globals/routes'

const styles = {
  contaner: css`
    background-color: ${colors.red};
    ${breakpoints({
      paddingTop: [40, 40, 100, 200, 200],
      paddingBottom: [80, 80, 100, 300, 300],
    })};
  `,
  title: css`
    ${spacer.mrB20};
  `,

  casette: css`
    ${breakpoints({
      top: [0, 0, -200, -300, -300],
      right: [0, 0, 0, -70, -70],
    })};
  `,
}
function PublicNotFound() {
  return (
    <>
      <section css={styles.contaner}>
        <div className='container relative'>
          <div className='row align-items-center'>
            <div className='col-md-5'>
              <Image
                css={styles.casette}
                className='d-none d-md-block'
                alt='casette '
                image='resetpassword/casette'
                sizes={{
                  lg: {
                    width: 817,
                  },
                  md: { width: 450 },
                }}
              />
            </div>
            <div className='offset-md-1 col-md-6 text-md-left text-center'>
              <Title
                as='h1'
                size='xxxxLarge'
                color={colors.black}
                weight='bold'
                css={styles.title}
              >
                Whoops! This page doesn’t exist.
              </Title>
              <LinkBtn
                size='large'
                variant='black'
                inline
                to={routes.login.to}
              >
                TAKE ME HOME
              </LinkBtn>
            </div>
          </div>
        </div>
      </section>
      <Wave bg={colors.red} fill={colors.white} type='footer' />
    </>
  )
}

export default PublicNotFound
