import React from 'react'
import PropTypes from 'prop-types'
import Title from '@dqp/common/components/Title/Title'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import Text from '@dqp/common/components/Text/Text'
import ModalContainer from '#components/ModalContainer/ModalContainer'
import plane from '#images/plane.svg'

function SuccessModal({ isOpen, closeModal, text }) {
  return (
    <ModalContainer isOpen={isOpen} closeModal={closeModal}>
      <div className='d-flex flex-column align-items-center text-center'>
        <img src={plane} alt='plane' css={spacer.mrB20} />
        <Title
          color={colors.black}
          size='xxxLarge'
          css={[spacer.mrB10]}
        >
          Success!
        </Title>
        {React.isValidElement(text) ? (
          text
        ) : (
          <Text css={[spacer.mrB20]} color={colors.grey}>
            {text}
          </Text>
        )}

        <Button
          size='medium'
          variant='blue'
          css={spacer.mrB10}
          onClick={closeModal}
        >
          CLOSE
        </Button>
      </div>
    </ModalContainer>
  )
}

SuccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  text: PropTypes.any.isRequired,
}

export default SuccessModal
