import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import Button from '@dqp/common/components/Button/Button'
import { misc, mixins } from '@dqp/common/styles/utilities'
import { Hamburger } from '@dqp/common/components/icons'
import { useLocation } from '@reach/router'
import HamburgerMenu from '#components/HamburgerMenu/HamburgerMenu'
// import AccountHamburgerMenu from '#components/HamburgerMenu/AccountHamburgerMenu'
import Logo from '#components/Logo'

const styles = {
  container: css`
    padding: 10px 15px;
    background-color: transparent;
    z-index: ${misc.navZindex};
    flex: 1;
    display: flex;
    align-items: center;
  `,
  icon: css`
    fill: white;
  `,
  button: css`
    :hover {
      opacity: 0.7;
    }
    ${mixins.transition()}
  `,
}

const initialState = {
  publicMenu: false,
  accountMenu: false,
}
function AppHeader() {
  const location = useLocation()
  const [menu, setMenu] = useState(initialState)
  useEffect(() => {
    setMenu(initialState)
  }, [location.pathname])
  return (
    <div css={styles.container} className='container-fluid'>
      <Logo width={100} noRedirect className='d-sm-none d-block' />
      <Button
        onClick={() => setMenu(s => ({ ...s, publicMenu: true }))}
        className='ml-auto'
        css={[styles.button]}
      >
        <Hamburger css={styles.icon} />
      </Button>
      {/* <Button
        onClick={() => setMenu(s => ({ ...s, accountMenu: true }))}
        css={styles.button}
      >
        <UserIcon css={styles.icon} />
      </Button> */}
      <HamburgerMenu
        closeMenu={() => setMenu(s => ({ ...s, publicMenu: false }))}
        isOpen={menu.publicMenu}
      />
      {/* <AccountHamburgerMenu
        closeMenu={() => setMenu(s => ({ ...s, accountMenu: false }))}
        isOpen={menu.accountMenu}
      /> */}
    </div>
  )
}
AppHeader.propTypes = {}
export default AppHeader
