import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { STRIPE_SUBSCRIPTION_FRAGMENT } from './getProfile'
import { useUserContext } from '#context/UserContext'

export const GET_USER_QUERY = gql`
  query getUser {
    getUser {
      id
      first_name
      last_name
      email
      email_verified_at
      watched_introduction
      subscriptions {
        ...STRIPE_SUBSCRIPTION_FRAGMENT
      }
    }
  }
  ${STRIPE_SUBSCRIPTION_FRAGMENT}
`

export const useGetUser = () => {
  const { setUser } = useUserContext()
  const { data: user, loading, error } = useQuery(GET_USER_QUERY, {
    onCompleted: response => {
      if (response.getUser) {
        setUser(storedUser => ({
          ...storedUser,
          user: {
            ...storedUser.user,
            email_verified_at: response.getUser.email_verified_at,
            watched_introduction:
              response.getUser.watched_introduction,
          },
        }))
      }
    },
  })
  return { user, loading, error }
}
