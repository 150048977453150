import React, { useState } from 'react'
import { css } from '@emotion/core'
import { spacer } from '@dqp/common/styles/utilities'
import Button, { LinkBtn } from '@dqp/common/components/Button/Button'
import { Hamburger } from '@dqp/common/components/icons'
import { ExternalLinkBtn } from '@dqp/common/components/Link/Link'
import HamburgerMenu from '#components/HamburgerMenu/HamburgerMenu'
import routes from '#globals/routes'

const styles = {
  container: css`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `,
  btn: css`
    display: none;
    @media (min-width: 480px) {
      display: block;
    }
    ${spacer.mrR40};
  `,
}
function MobileNav() {
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const openMenu = () => {
    setIsMenuVisible(true)
  }

  const closeMenu = () => {
    setIsMenuVisible(false)
  }

  return (
    <>
      <div css={styles.container}>
        <LinkBtn
          size='medium'
          variant='black'
          inline
          to={routes.signup.to}
          css={styles.btn}
        >
          TRY FOR FREE
        </LinkBtn>
        <Button onClick={openMenu} aria-label='open-menu'>
          <Hamburger />
        </Button>
      </div>
      <HamburgerMenu closeMenu={closeMenu} isOpen={isMenuVisible} />
    </>
  )
}

export default MobileNav
