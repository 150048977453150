import React from 'react'
import PropTypes from 'prop-types'
import Imgix from 'react-imgix'
import { ExternalLink } from '@dqp/common/components/Link/Link'
import { baseImgixURL } from '#globals/constants'
import routes from '#globals/routes'

function Logo({ className, width, noRedirect }) {
  const Tag = noRedirect ? 'div' : ExternalLink
  return (
    <Tag to={routes.home.to} className={className}>
      <Imgix
        src={`${baseImgixURL}logo.png`}
        alt='logo'
        width={width || 110}
      />
    </Tag>
  )
}
Logo.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  noRedirect: PropTypes.bool,
}
Logo.defaultProps = {
  className: '',
  width: 110,
  noRedirect: false,
}
export default Logo
