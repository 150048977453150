import React, {
  useContext,
  createContext,
  useCallback,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { useUserContext } from './UserContext'
import useModal from '#utils/useModal'
import { useUpdateUser } from '#api/updateUser'

const WelcomeTourModalContext = createContext()

export const useWelcomeTourModalContext = () =>
  useContext(WelcomeTourModalContext)

const WelcomeTourModalProvider = ({ children }) => {
  const { user } = useUserContext()
  const { updateUser } = useUpdateUser()
  const { watched_introduction } = user.user
  const { isOpen, openModal, closeModal } = useModal(
    !watched_introduction,
  )

  const closeWelcomeTourModal = useCallback(() => {
    if (!watched_introduction) {
      updateUser({ variables: { watched_introduction: true } })
    }
    closeModal()
  }, [updateUser, closeModal, watched_introduction])
  const showWelcomeTourModal = useCallback(openModal, [])

  const value = useMemo(
    () => ({ showWelcomeTourModal, closeWelcomeTourModal, isOpen }),
    [showWelcomeTourModal, closeWelcomeTourModal, isOpen],
  )

  return (
    <WelcomeTourModalContext.Provider value={value}>
      {children}
    </WelcomeTourModalContext.Provider>
  )
}
WelcomeTourModalProvider.propTypes = {
  children: PropTypes.any.isRequired,
}
export default WelcomeTourModalProvider
