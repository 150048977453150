import React, { useContext, createContext, useState } from 'react'
import PropTypes from 'prop-types'

const ThemeColorContext = createContext({
  themeColor: {},
  setThemeColor: () => {},
})

export const useThemeColor = () => {
  const { themeColor, setThemeColor } = useContext(ThemeColorContext)
  return {
    themeColor,
    setThemeColor,
  }
}

export const ThemeColorProvider = ({ children }) => {
  const [themeColor, setThemeColor] = useState()

  return (
    <ThemeColorContext.Provider
      value={{
        themeColor,
        setThemeColor,
      }}
    >
      {children}
    </ThemeColorContext.Provider>
  )
}

ThemeColorProvider.propTypes = {
  children: PropTypes.any.isRequired,
}
