import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { spacer, colors, misc } from '@dqp/common/styles/utilities'
import Link from '@dqp/common/components/Link/Link'
import Text from '@dqp/common/components/Text/Text'
import { lighten, triangle } from 'polished'
import Portal from '#components/Portal/Portal'

const styles = {
  link: ({ backgroundColor }) => css`
    height: 65px;
    display: flex;
    align-items: center;
    padding: 15px 0px;
    flex-wrap: nowrap;
    white-space: nowrap;
    border: 0;
    border-right: 5px solid transparent;
    :hover {
      text-decoration: none;
      background-color: ${backgroundColor};
    }
  `,
  icon: ({ padding }) => css`
    ${spacer.mrR10};
    flex: 0 0 70px;
    padding: ${padding};
  `,
  toolTipOverlay: ({ left, top }) => css`
    position: absolute;
    top: ${`${top}px`};
    left: ${`${left}px`};
    z-index: 3001;
    z-index: ${misc.hamburgerMenuZIndex + 1};
    background-color: ${colors.white};
    padding: 5px;
    border-radius: ${misc.borderRadius.md};
    box-shadow: ${misc.boxShadow.default};
    transform: translateY(-50%);
  `,
  tooltipNotch: css`
    ${triangle({
      pointingDirection: `left`,
      width: `8px`,
      height: `10px`,
      foregroundColor: colors.white,
    })};
    position: absolute;
    left: 0;
    transform: translate(-100%, -50%);
    top: 50%;
  `,
}
const activeStyle = {
  borderColor: colors.purpleDark,
  backgroundColor: lighten(0.25, colors.purpleDark),
}

function SidebarLink({
  to,
  icon,
  text,
  partiallyActive,
  LinkType,
  iconPadding,
  onClick,
  isSidebarOpen,
  ...rest
}) {
  const linkProps = LinkType === Link && {
    to,
    partiallyActive,
    activeStyle,
  }

  const [coords, setCoords] = useState({}) // takes current element coordinates
  const [isHovered, setHovered] = useState(false) // toggles hover
  const handleMouseEnter = event => {
    const rect = event.target.getBoundingClientRect()
    setCoords({
      left: rect.x + rect.width + 10,
      top: rect.y + window.scrollY + rect.height / 2,
    })
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }

  const handleOnClick = event => {
    onClick(event)
    setHovered(false)
  }

  return (
    <LinkType
      css={styles.link({
        backgroundColor: activeStyle.backgroundColor,
      })}
      {...linkProps}
      {...rest}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleOnClick}
    >
      <div css={styles.icon({ padding: iconPadding })}>
        <img
          src={icon}
          alt={text}
          css={css`
            display: block;
            width: 100%;
          `}
        />
      </div>
      <Text color={colors.black} size='large'>
        {text}
      </Text>
      {isHovered && !isSidebarOpen && (
        <Portal>
          <div css={styles.toolTipOverlay({ ...coords })}>
            <div css={styles.tooltipNotch} />
            <Text color={colors.black} size='medium'>
              {text}
            </Text>
          </div>
        </Portal>
      )}
    </LinkType>
  )
}

export const SidebarLinkPropTypes = {
  to: PropTypes.string,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  iconPadding: PropTypes.string,
  partiallyActive: PropTypes.bool,
  isSidebarOpen: PropTypes.bool,
  onClick: PropTypes.func,
  LinkType: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
}
SidebarLink.propTypes = SidebarLinkPropTypes
SidebarLink.defaultProps = {
  partiallyActive: true,
  LinkType: Link,
  to: '',
  iconPadding: '20px',
  onClick: () => {},
  isSidebarOpen: false,
}

export default SidebarLink
