import React from 'react'
import PropTypes from 'prop-types'
import { Global, css, keyframes } from '@emotion/core'
import { misc, colors } from '@dqp/common/styles/utilities'

const spWaveAf = keyframes`
	from { transform: scale(0.5,0.5); opacity: 0; }
  to { transform: scale(1,1); opacity: 1; }
  `

const spWaveBe = keyframes`
	from { -webkit-transform: scale(1,1); opacity: 1; }
	to { -webkit-transform: scale(1.5,1.5); opacity: 0; }
`

const waveStyles = css`
  border-radius: 50%;
  position: relative;
  opacity: 1;
  width: 3rem;
  height: 3rem;
  clear: both;
  margin: 20px auto;
  &:before,
  &:after {
    content: '';
    border: 1px solid #333333;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
  }
  &:before {
    transform: scale(1, 1);
    opacity: 1;
    animation: ${spWaveBe} 0.6s infinite linear;
  }
  &:after {
    transform: scale(0, 0);
    opacity: 0;
    animation: ${spWaveAf} 0.6s infinite linear;
  }
`

const wrapperStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.greyLightest};
  display: flex;
  align-items: center;
  justify-content: center;
`

const getPageHeight = () => {
  if (typeof window !== 'undefined') {
    return `${window.innerHeight}px`
  }
  return ''
}

const Loader = ({ height, isRelative, atPageHeight }) => {
  return (
    <>
      {atPageHeight && (
        <Global
          styles={css`
            html,
            body {
              overflow: hidden;
            }
          `}
        />
      )}
      <div
        className={isRelative ? 'relative' : ''}
        style={{ height: atPageHeight ? getPageHeight() : height }}
      >
        <div
          css={wrapperStyles}
          style={{
            height: '100%',
            zIndex: !isRelative ? misc.modalZindex : 'auto',
          }}
        >
          <div css={waveStyles} />
        </div>
      </div>
    </>
  )
}

Loader.propTypes = {
  height: PropTypes.number,
  isRelative: PropTypes.bool,
  atPageHeight: PropTypes.bool,
}

Loader.defaultProps = {
  height: 300,
  isRelative: false,
  atPageHeight: false,
}

export default Loader
