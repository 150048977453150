import { css } from '@emotion/core'
import {
  colors,
  spacer,
  mixins,
  misc,
  breakpoints,
} from '@dqp/common/styles/utilities'

export const modalContainerStyles = ({
  isFirstSlide,
  isLastSlide,
}) => css`
  /* ${mixins.respondToMax(misc.viewport['b-vp'])} {
    & .container,
    & .row,
    & .row,
    & .row > div,
    & .modal-contents,
    & .modal-content-wrapper {
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      & > div {
        border-radius: 0;
      }
    }

    .modal-content-wrapper {
      display: flex;
      flex-direction: column;
      .slick-slider,
      .slick-list,
      .slick-track {
        height: 100%;
        min-height: 0;
        min-width: 0;
      }
      .slick-slide > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  } */
  .modal-padding {
    padding: 0;
  }
  .slick-slider {
    position: relative;
    .slick-prev,
    .slick-next {
      z-index: 1;
      position: absolute;
      top: auto;
      bottom: 0;
      ${breakpoints({
        opacity: [0, isFirstSlide ? 0 : 1],
      })}
    }
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
      color: ${
        isLastSlide ? colors['grey-shade-1'] : colors.primaryLinkHover
      };
      cursor: ${isLastSlide ? 'not-allowed' : 'pointer'};
    }
  }
`

export const carouselDotsStyles = css`
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  & > li {
    cursor: pointer;
    ${spacer.mrR10};
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: ${colors.greyMedium};
    border-radius: 50%;
    text-align: center;
    color: transparent;
    overflow: hidden;
    :last-child {
      margin-right: 0;
    }
    &.slick-active {
      background-color: ${colors.btnBlueActive};
    }
  }
`

export const slideContainerStyles = css`
  ${mixins.respondToMax(misc.viewport['b-vp'])} {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  text-align: center;
  position: relative;
`
