import React from 'react'
import { Helmet as ReactHelmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import LoewHeavy from '@dqp/common/styles/fonts/LoewHeavy/LoewHeavy.woff2'
import MuliBold from '@dqp/common/styles/fonts/Muli/Muli-Bold.woff2'
import MuliRegular from '@dqp/common/styles/fonts/Muli/Muli-Regular.woff2'
import { baseImgixURL } from '#globals/constants'

const Helmet = props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  )
  // eslint-disable-next-line import/no-dynamic-require
  const getFavicon = path => require(`#images/favicons/${path}`)
  return (
    <ReactHelmet
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      {...props}
    >
      <link rel='preconnect' href={baseImgixURL} />
      <link rel='dns-prefetch' href={baseImgixURL} />
      <link
        rel='preload'
        href={LoewHeavy}
        as='font'
        type='font/woff2'
        crossOrigin='anonymous'
      />
      <link
        rel='preload'
        href={MuliBold}
        as='font'
        type='font/woff2'
        crossOrigin='anonymous'
      />
      <link
        rel='preload'
        href={MuliRegular}
        as='font'
        type='font/woff2'
        crossOrigin='anonymous'
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='57x57'
        href={getFavicon('apple-touch-icon-57x57.png')}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='114x114'
        href={getFavicon('apple-touch-icon-114x114.png')}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='72x72'
        href={getFavicon('apple-touch-icon-72x72.png')}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='144x144'
        href={getFavicon('apple-touch-icon-144x144.png')}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='60x60'
        href={getFavicon('apple-touch-icon-60x60.png')}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='120x120'
        href={getFavicon('apple-touch-icon-120x120.png')}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='76x76'
        href={getFavicon('apple-touch-icon-76x76.png')}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='152x152'
        href={getFavicon('apple-touch-icon-152x152.png')}
      />
      <link
        rel='icon'
        type='image/png'
        href={getFavicon('favicon-196x196.png')}
        sizes='196x196'
      />
      <link
        rel='icon'
        type='image/png'
        href={getFavicon('favicon-96x96.png')}
        sizes='96x96'
      />
      <link
        rel='icon'
        type='image/png'
        href={getFavicon('favicon-32x32.png')}
        sizes='32x32'
      />
      <link
        rel='icon'
        type='image/png'
        href={getFavicon('favicon-16x16.png')}
        sizes='16x16'
      />
      <link
        rel='icon'
        type='image/png'
        href={getFavicon('favicon-128.png')}
        sizes='128x128'
      />
      <meta name='application-name' content='&nbsp;' />
      <meta name='msapplication-TileColor' content='#FFFFFF' />
      <meta
        name='msapplication-TileImage'
        content={getFavicon('mstile-144x144.png')}
      />
      <meta
        name='msapplication-square70x70logo'
        content={getFavicon('mstile-70x70.png')}
      />
      <meta
        name='msapplication-square150x150logo'
        content={getFavicon('mstile-150x150.png')}
      />
      <meta
        name='msapplication-wide310x150logo'
        content={getFavicon('mstile-310x150.png')}
      />
      <meta
        name='msapplication-square310x310logo'
        content={getFavicon('mstile-310x310.png')}
      />
    </ReactHelmet>
  )
}

export default Helmet
