import React from 'react'
import { css, Global } from '@emotion/core'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { misc, spacer } from '@dqp/common/styles/utilities'
import { Cross } from '@dqp/common/components/icons'
import Button from '@dqp/common/components/Button/Button'

Modal.setAppElement('body')

const styles = {
  modalGlobalStyles: css`
    .ReactModal__Body--open {
      overflow: hidden;
    }
  `,
  modal: css`
    max-width: 620px;
    display: block;
    margin: auto;
    ${spacer.mrBT60};
    pointer-events: none;
    .container {
      pointer-events: none;
      > * {
        pointer-events: all;
      }
    }

    :focus {
      outline: 0;
    }
  `,
  content: css`
    ${spacer.padLR40};
    ${spacer.padBT40};
    border-radius: 4px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    position: relative;
  `,
  closeButton: css`
    position: absolute;
    right: 15px;
    top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      opacity: 0.7;
    }
  `,
  crossIcon: css`
    width: 16px;
    height: 16px;
  `,
}

const defaultOverlayStyles = {
  overlay: {
    zIndex: misc.modalZindex,
    backgroundColor: `rgba(123, 109, 255, 0.9)`,
    overflowY: 'auto',
  },
}

function ModalContainer(props) {
  return (
    <Modal
      css={[styles.modal, props.modalContainerStyles]}
      style={defaultOverlayStyles}
      shouldCloseOnEsc={props.shouldCloseOnEsc}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
    >
      <Global styles={styles.modalGlobalStyles} />
      <div className='container'>
        <div className='row'>
          <div
            className='col-12'
            css={[styles.content, props.containerStyles]}
          >
            {props.children}
            <Button
              onClick={props.closeModal}
              css={[styles.closeButton, props.crossBtnStyles]}
            >
              <Cross css={styles.crossIcon} />
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
ModalContainer.defaultProps = {
  shouldCloseOnOverlayClick: true,
  shouldCloseOnEsc: true,
  crossBtnStyles: '',
  containerStyles: '',
  modalContainerStyles: '',
}
ModalContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  closeModal: PropTypes.func.isRequired,
  shouldCloseOnEsc: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  crossBtnStyles: PropTypes.any,
  modalContainerStyles: PropTypes.any,
  containerStyles: PropTypes.any,
}
export default ModalContainer
