import React from 'react'
import { colors } from '@dqp/common/styles/utilities'
import BackgroundWave from './BackgroundWave'
import { useThemeColor } from '#context/ThemeColorContext'

const BackgroundWaveContainer = () => {
  const { themeColor: backgroundWaveColor } = useThemeColor()
  return <BackgroundWave bg={backgroundWaveColor || colors.purple} />
}

export default BackgroundWaveContainer
