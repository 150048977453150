import React from 'react'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import { mixins, misc } from '@dqp/common/styles/utilities'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import SidebarContainer from '../Sidebar/SidebarContainer'
import AppHeader from '#components/AppHeader/AppHeader'
import { ThemeColorProvider } from '#context/ThemeColorContext'
import BackgroundWaveContainer from '#components/BackgroundWave/BackgroundWaveContainer'
import { NotificationsContainer } from '#containers/NotificationsContainer/NotificationsContainer'
import { NotificationsProvider } from '#context/NotificationsContext'
import SuccessOrErrorModalProvider from '#context/SuccessOrErrorModalContext'
import WelcomeTourModal from '#components/WelcomeTourModal/WelcomeTourModal'
import WelcomeTourModalProvider from '#context/WelcomeTourModalContext'

const styles = {
  main: css`
    min-height: 100vh;
    position: relative;
    margin-left: 70px;
    padding-bottom: 30px;
    ${mixins.transition()};
    ${mixins.respondToMax(misc.viewport['c-vp'])} {
      margin-left: 0 !important;
    }
  `,
}
const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY)
function AppLayout({ children }) {
  return (
    <>
      <WelcomeTourModalProvider>
        <SidebarContainer />
        <Elements stripe={stripePromise}>
          <ThemeColorProvider>
            <NotificationsProvider>
              <SuccessOrErrorModalProvider>
                <WelcomeTourModal />
                <main id='content-js' css={styles.main}>
                  <AppHeader />
                  <NotificationsContainer />
                  <BackgroundWaveContainer />
                  {children}
                </main>
              </SuccessOrErrorModalProvider>
            </NotificationsProvider>
          </ThemeColorProvider>
        </Elements>
      </WelcomeTourModalProvider>
    </>
  )
}

AppLayout.propTypes = {
  children: PropTypes.any.isRequired,
}

export default AppLayout
