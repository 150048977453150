import React from 'react'
import PropTypes from 'prop-types'
import PublicFooter from '#components/PublicFooter/PublicFooter'
import routes, { headerRoutes } from '#globals/routes'
import PublicHeader from '#components/PublicHeader/PublicHeader'

const PublicLayout = ({ children, headerColor }) => {
  return (
    <>
      <PublicHeader
        headerColor={headerColor}
        routes={headerRoutes}
        loginRoute={routes.login}
        signupRoute={routes.signup}
      />
      <main>{children}</main>
      <PublicFooter />
    </>
  )
}

PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
  headerColor: PropTypes.string.isRequired,
}

export default PublicLayout
