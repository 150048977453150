import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import AppLayout from './Layout/AppLayout'
import Helmet from './Helmet'
import routes from '#globals/routes'
import { useUserContext } from '#context/UserContext'
import { useGetUser } from '#api/getUser'

const PrivateRoute = ({ component: Component, title, ...rest }) => {
  const { user } = useUserContext()
  useGetUser()
  if (!user) {
    navigate(routes.login.to)
    return null
  }

  return (
    <AppLayout>
      <Helmet title={title} />
      <Component {...rest} />
    </AppLayout>
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
}

export default PrivateRoute
