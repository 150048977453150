import Loadable from 'react-loadable'
import Loader from '#components/Loader/Loader'

export default Loadable({
  loader: () =>
    import(
      './OverviewDashboard' /* webpackChunkName: "OverviewDashboard" */
    ),
  loading: Loader,
})
