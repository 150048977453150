import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { useUserContext } from '#context/UserContext'

const UPDATE_USER_MUTAION = gql`
  mutation updateUser(
    $first_name: String
    $last_name: String
    $email: String
    $watched_introduction: Boolean
    $current_password: String
    $password: String
    $password_confirmation: String
  ) {
    updateUser(
      input: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        watched_introduction: $watched_introduction
        current_password: $current_password
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      id
      first_name
      last_name
      email
      watched_introduction
    }
  }
`
export const useUpdateUser = () => {
  const { setUser, user } = useUserContext()
  const [updateUser, { data, loading, error }] = useMutation(
    UPDATE_USER_MUTAION,
    {
      onCompleted: response => {
        if (response.updateUser) {
          const {
            first_name,
            last_name,
            email,
            watched_introduction,
          } = response.updateUser
          setUser({
            ...user,
            user: {
              ...user.user,
              first_name,
              last_name,
              email,
              watched_introduction,
            },
          })
        }
      },
    },
  )

  return { updateUser, data, loading, error }
}
