import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

export const STRIPE_SUBSCRIPTION_FRAGMENT = gql`
  fragment STRIPE_SUBSCRIPTION_FRAGMENT on StripeSubscription {
    id
    is_active
    stripe_id
    stripe_status
    ends_at
    current_period_end
    type
    profile {
      id
      name
      icon {
        id
        icon
      }
    }
    product {
      id
      name
      value: id
      label: name
      color
    }
  }
`
export const GET_PROFILE_QUERY = gql`
  query getProfile($id: ID!) {
    getProfile(id: $id) {
      id
      name
      subscriptions {
        ...STRIPE_SUBSCRIPTION_FRAGMENT
      }
    }
  }
  ${STRIPE_SUBSCRIPTION_FRAGMENT}
`

export const useGetProfile = ({ profileId }) => {
  const { data: profile, loading, error } = useQuery(
    GET_PROFILE_QUERY,
    {
      variables: { id: profileId },
      // fetchPolicy: 'cache-and-network',
    },
  )
  return { profile, loading, error }
}
