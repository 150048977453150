import React from 'react'
import { css } from '@emotion/core'
import { colors } from '@dqp/common/styles/utilities'
import Wave from '@dqp/common/components/Wave/Wave'
import PropTypes from 'prop-types'
import streamers from '#images/streamers.svg'

const styles = {
  container: css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    overflow: hidden;
  `,
  upperPart: css`
    width: 100%;
    height: 550px;
    transform: rotateY(180deg);
  `,
  lowerPart: css`
    flex-grow: 1;
    background-color: ${colors.greyLightest};
  `,
  wave: css`
    transform: rotateY(180deg);
  `,
  ribbons: css`
    position: absolute;
    left: 50%;
    top: 50%;
    width: 110%;
    height: 110%;
    transform: translate(-50%, -58%);
  `,
}

function BackgroundWave({ bg = colors.yellow }) {
  return (
    <div className='d-flex flex-column' css={styles.container}>
      <div css={styles.upperPart}>
        <div
          className='h-100 overflow-hidden relative'
          css={css`
            background-color: ${bg};
          `}
        >
          <img
            alt='ribbons'
            css={styles.ribbons}
            src={streamers}
            className='d-none d-md-block'
          />
        </div>
        <div className='w-100 overflow-hidden'>
          <Wave
            css={styles.wave}
            bg={bg}
            fill={colors.greyLightest}
          />
        </div>
      </div>
      <div css={styles.lowerPart} />
    </div>
  )
}

BackgroundWave.propTypes = {
  bg: PropTypes.string.isRequired,
}
export default BackgroundWave
