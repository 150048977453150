import React from 'react'
import PropTypes from 'prop-types'
import { ChevronRight } from '@dqp/common/components/icons'
import { colors } from '@dqp/common/styles/utilities'
import { css } from '@emotion/core'
import Button from '@dqp/common/components/Button/Button'

const styles = {
  icon: css`
    height: 30px;
    fill: ${colors.btnBlueActive};
    margin: 0 30px;
  `,
}
const PrevArrow = props => {
  const { className, style, onClick } = props
  return (
    <Button className={className} style={style} onClick={onClick}>
      <ChevronRight
        css={css`
          transform: rotate(180deg);
          ${styles.icon}
        `}
      />
    </Button>
  )
}

const NextArrow = props => {
  const { className, style, onClick } = props
  return (
    <Button className={className} style={style} onClick={onClick}>
      <ChevronRight css={styles.icon} />
    </Button>
  )
}

const commonProps = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

const commonDefaultProps = {
  className: '',
  style: {},
  onClick: null,
}

PrevArrow.propTypes = commonProps
NextArrow.propTypes = commonProps

PrevArrow.defaultProps = commonDefaultProps
NextArrow.defaultProps = commonDefaultProps

export default { PrevArrow, NextArrow }
